.button {
  color: #333;
  background-color: #fff;
  background-image: linear-gradient(#fff, #cccccc);
  border: 1px solid #cccccc;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  font-family: "Open sans", sans-serif;
  font-size: 1.4rem;
  padding: 0.4rem 1rem;
  text-align: center;
  border-radius: 0.5rem;
  line-height: 1.4;
  margin-bottom: 1.4rem;
  box-shadow: 0px 3px 5px 0px #999;
  width: 70%;
}

.createButton {
  color: #333;
  background-color: #fff;
  background-image: linear-gradient(#fff, #cccccc);
  border: 1px solid #cccccc;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  font-family: "Open sans", sans-serif;
  font-size: 1rem;
  padding: 0.2rem 0.2rem;
  text-align: center;
  border-radius: 0.2rem;
  line-height: 1.4;
  margin-bottom: 1.4rem;
  box-shadow: 0px 3px 5px 0px #999;
}

.editButton {
    width: 50%;
    margin: 1em auto;
}

.button:hover {
  background-color: #b3b3b3;
  background-image: none;
}

.button:active {
  background-color: #a6a6a6;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.full-width-button {
width: 100%;
}

.blue-button {
  color: #fff;
  background-color: #0074d9;
  background-image: linear-gradient(#0074d9, #003d73);
  border-color: #003d73;
}

.blue-button:hover {
  background-color: #002240;
  background-image: none;
}

.blue-button:active {
  background-color: #001527;
  background-image: none;
}

.green-button {
  color: #fff;
  background-color: #2ecc40;
  background-image: linear-gradient(#2ecc40, #1b7926);
  border-color: #1b7926;
}

.green-button:hover {
  background-color: #124f19;
  background-image: none;
}

.green-button:active {
  background-color: #0d3a12;
  background-image: none;
}

.reports {
    width: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5em;
    min-height: 40em;
}

.reports a {
    text-decoration: none;
}

.reports a:hover {
    text-decoration: underline;
}

.weekNav {
    padding: 0;
}

.weekNav li {
    display: flex;
    padding-right: 0.5em;
    text-decoration: none;
}

.weekNav li a {
    color: blue;
    text-decoration: none;
}

.weekNav li a:hover {
    color: #555;
    text-decoration: underline;
}

.reports h1 {
    margin-bottom: 0.5em;
}
